import React, { useContext } from "react";
import Container from "@mui/material/Container";
import { Typography, Breadcrumbs, Stack } from "@mui/material";
import CartModal from "../../../../../../components/ecommerce/store/cartModal";
import SEO from "../../../../../../components/layout/seo";

import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import { CartProvider } from "use-shopping-cart";

import Layout from "../../../../../../components/layout/layout-ecommerce";
import { Link, navigate } from "gatsby";
import MicrorecLowerpart from "../../../../../../components/ecommerce/section_beamsplitter/microrec_lowerpart";
import Accessories from "../../../../../../components/ecommerce/sections_upsell/accessories";
import context from "react-bootstrap/esm/AccordionContext";
import AddedProductTopSection from "../../../../../../components/ecommerce/sections_upsell/added-product_beamsplitter";
import CircleIcon from "@mui/icons-material/Circle";
import { myContext } from "../../../../../../utils/provider";

const isBrowser = typeof window !== "undefined";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#3D3838",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const UpsellPage = ({ location }) => {
  const context = useContext(myContext);

  const getCurrency = () => {
    if (context.ecommerceDollar) {
      return "USD";
    } else {
      return "EUR";
    }
  };

  const currentPath = location.pathname;

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/store/beamsplitter">
      <CircleIcon
        fontSize="small"
        sx={{
          color: currentPath === "/store/beamsplitter" ? "#FFA100" : "#CACACA",
        }}
      />
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/store/beamsplitter/microrec"
    >
      <CircleIcon
        fontSize="small"
        sx={{
          color:
            currentPath === "/store/beamsplitter/microrec"
              ? "#FFA100"
              : "#CACACA",
        }}
      />
    </Link>,
    <Link
      underline="hover"
      key="3"
      color="inherit"
      href="/store/beamsplitter/microrec/software"
    >
      <CircleIcon
        fontSize="small"
        sx={{
          color:
            currentPath === "/store/beamsplitter/microrec/software"
              ? "#FFA100"
              : "#CACACA",
        }}
      />
    </Link>,
    <Link
      underline="hover"
      key="4"
      color="inherit"
      href="/store/beamsplitter/microrec/software/accessories"
    >
      <CircleIcon
        fontSize="small"
        sx={{
          color:
            currentPath ===
              "/store/beamsplitter/microrec/software/accessories" ||
            currentPath === "/store/beamsplitter/microrec/software/accessories/"
              ? "#FFA100"
              : "#CACACA",
        }}
      />
    </Link>,
  ];
  try {
    return (
      <ThemeProvider theme={cs_theme}>
        <CartProvider
          stripe={process.env.STRIPE_PUBLISHABLE_KEY}
          currency={getCurrency()}
        >
          <Layout helmetName="Accesories">
            {" "}
            <Container id="accesories_page" disableGutters maxWidth={"lg"}>
              <SEO
                title="Custom Surgical Store | Accesories"
                keywords={[`gatsby`, `application`, `react`]}
              />

              <CartModal />
              <Stack spacing={2} style={{ marginTop: "120px" }}>
                <Breadcrumbs
                  separator={
                    <Typography variant="h6">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  }
                  aria-label="breadcrumb"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    gap: 2,
                  }}
                >
                  {breadcrumbs}
                </Breadcrumbs>{" "}
              </Stack>
              <AddedProductTopSection />
              <Accessories />
              <MicrorecLowerpart />
            </Container>
          </Layout>
        </CartProvider>
      </ThemeProvider>
    );
  } catch {
    if (isBrowser) {
      navigate("/");
    }
    return (
      <CartProvider
        stripe={process.env.STRIPE_PUBLISHABLE_KEY}
        currency={getCurrency()}
      >
        <Container id="accesories_page" disableGutters maxWidth={false}>
          <Layout></Layout>
        </Container>
      </CartProvider>
    );
  }
};
export default UpsellPage;
